import axios from "axios";
import { getToken } from "../storage";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const post = async (endpoint, body, headers) => {
  try {
    const url = `${baseUrl}${endpoint}`;
    const result = await axios(url, {
      method: "POST",
      headers: headers
        ? headers
        : {
            Authorization: "Bearer " + getToken(),
            "content-type": "application/json",
          },
      data: body,
    });
    return result;
  } catch (error) {
    const { data, message } = error?.response || {};
    const validMessage =
      data?.error || message || "Ocurrió un error en la conexión";
    throw new Error(validMessage);
  }
};
