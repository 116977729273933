const token = "token";

export function getToken() {
  if (!window) return null;
  return sessionStorage.getItem(token);
}

export function setToken(value) {
  if (!window) return;
  sessionStorage.setItem(token, value);
}
